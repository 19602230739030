<template>
  <v-chip v-if="percentage > 0" dark :color="color">-{{percentage.toFixed(0)}}%</v-chip>
</template>

<script>
export default {
  name: "ActivityPenaltyScoreChip",
  components: {
  },
  props: {
    base: Number,
    penalty: Number,
  },
  computed: {
    percentage() {
      return this.base == 0 || this.penalty == 0 ? 0 : Math.round((this.penalty / this.base) * 100);
    },
    color() {
      if (this.percentage >= 50) return "red darken-4";
      if (this.percentage >= 40) return "red darken-2";
      if (this.percentage >= 30) return "red";
      if (this.percentage >= 20) return "orange darken-2";
      if (this.percentage >= 15) return "orange";
      if (this.percentage >= 10) return "green darken-4";
      if (this.percentage >= 5) return "green darken-2";
      return "success";
    },
  }
};
</script>

<style lang="scss">
</style>
<template>
    <img :src="`https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=${encodeURIComponent(data)}`" width="400" height="400" style="padding:25px; max-width:100%;"/>
</template>

<script>
export default {
  name: "QrCode",
  components: {
  },
  props: {
    data: String,
  },
  computed: {
  }
};
</script>

<style lang="scss">
</style>
<template>
    <div :id="`videoplayer-${videoId}`" class="apivideo-player">
    </div>
</template>

<script>
import { PlayerSdk } from "@api.video/player-sdk";

export default {
  name: "ApiVideoPlayer",
  components: {
  },
  props: {
    videoId: String,
  },
  computed: {
  },
  async mounted() {
    const player = new PlayerSdk(`#videoplayer-${this.videoId}`, {
      id: this.videoId,
      hideTitle: true,
    });
    player.showControls(); // display all controls ...
    player.hideControls(["download", "subtitles"]); 
  },
};
</script>

<style lang="scss">
  .apivideo-player {
    max-width: 400px;
    aspect-ratio: 3/2;
    iframe { border-width: 0 !important;}
  }
</style>
<template>
  <v-chip v-if="percentage > 0" dark :color="color">+{{percentage.toFixed(0)}}%</v-chip>
</template>

<script>
export default {
  name: "ActivityBonusScoreChip",
  components: {
  },
  props: {
    base: Number,
    bonus: Number,
  },
  computed: {
    percentage() {
      return this.base == 0 || this.bonus == 0 ? 0 : Math.round((this.bonus / this.base) * 100);
    },
    color() {
      if (this.percentage >= 10) return "green darken-4";
      if (this.percentage >= 5) return "green darken-2";
      return "success";
    },
  }
};
</script>

<style lang="scss">
</style>
<template>
  <v-card-title v-if="event && race" class="subtitle result--filter">  
    <span v-if="showRace && (races.length <= 1 || !showRaceDropdown)">{{ race.name }}</span>
    <v-select 
      v-else-if="showRace"
      :items="races" 
      item-text="name" 
      item-value="id" 
      v-model="raceId" 
      hide-details
      solo
      rounded
      style="max-width:250px" 
      class="mr-4 mt-0 pt-0 d-inline-block" 
      aria-label="Select a leaderboard to show"
      @change="adminMode ? $router.push({ name: 'eventmanagerRaceResults', params: { id: event.id, raceId: raceId} }) : $router.push({ name: 'race', params: { id: event.id, raceId: raceId} })"
      />
    <v-spacer v-if="showRace"/> 
    <v-btn-toggle v-if="showGenderFilter && meta && meta.has_genders && !race.team" v-model="gender" @change="search" class="result-filter--gender">
      <v-btn text value="*" :small="$vuetify.breakpoint.xs">
        {{$t('shared.gender-all')}}
      </v-btn>
      <v-btn value="F" :title="$t('shared.gender-female')" :small="$vuetify.breakpoint.xs">
        {{$t('shared.gender-female')}}
      </v-btn>
      <v-btn value="M" :title="$t('shared.gender-male')" :small="$vuetify.breakpoint.xs">
        {{$t('shared.gender-male')}}
      </v-btn>
      <v-btn v-if="race.individual_gender_results == 'M_F_O' || race.individual_gender_results == 'M_F_O_U'" value="O" :title="$t('shared.gender-other')" :small="$vuetify.breakpoint.xs">
        {{$t('shared.gender-other')}}
      </v-btn>
      <v-btn v-if="race.individual_gender_results == 'M_F_O_U'" value="U" :title="$t('shared.gender-undisclosed')" :small="$vuetify.breakpoint.xs">
        {{$t('shared.gender-undisclosed')}}
      </v-btn>
    </v-btn-toggle>
    <v-btn-toggle v-if="showGenderFilter && race.team && race.team_gender_results" v-model="gender" @change="search" class="result-filter--gender">
      <v-btn text value="*" :small="$vuetify.breakpoint.xs">
        {{$t('shared.gender-all')}}
      </v-btn>
      <v-btn value="F" :title="$t('shared.gender-female')" :small="$vuetify.breakpoint.xs">
        {{$t('shared.gender-female')}}
      </v-btn>
      <v-btn value="M" :title="$t('shared.gender-male')" :small="$vuetify.breakpoint.xs">
        {{$t('shared.gender-male')}}
      </v-btn>
      <v-btn v-if="race.team_gender_results == 'M_F_MIX'" text value="mix" :title="$t('events.race.filter-team-mixed')" :small="$vuetify.breakpoint.xs">
        {{$t('shared.gender-mix')}}
      </v-btn>
    </v-btn-toggle>
    <v-spacer/>
    <v-select 
      v-if="showCategoryFilter && meta && meta.categories && meta.categories.length > 0" 
      v-model="category" 
      :items="categoryList"
      solo
      rounded
      clearable
      hide-details
      aria-label="Select a category to filter on"
       :small="$vuetify.breakpoint.xs"
      :placeholder="$t('events.race.category')"
      :class="`result-filter--category ${ $vuetify.breakpoint.xs ? 'small' : ''}`" 
      @change="search">
    </v-select>
    <v-spacer/>
    <v-text-field 
      v-if="!(this.race.top_x || race.top_x === 0)"
      v-model="resultSearch" 
      solo 
      rounded 
      clearable 
      hide-details 
      aria-label="Search results by team or participant name."
      v-on:keyup.enter="search" 
      @click:clear="resultSearch='';search()" 
      @click:prepend="search" 
      prepend-icon="fa-search" 
      :class="`result-filter--search ${ $vuetify.breakpoint.xs ? 'small' : ''}`" 
      :placeholder="race.team ? $t('events.race.search-team-or-member') : $t('events.race.search-participant')" 
    />
    <v-switch 
      v-if="false && race.type === 'HYBRID' && !race.team" 
      v-model="onlyOfficialResults" 
      :label="$t('events.race.filter-official-only')" 
      class="full-flex-row"
      hide-details 
      @change="search"
      />
    <v-btn-toggle 
      v-if="race.type === 'HYBRID' && !race.team" 
      v-model="onlyOfficialResults" 
      @change="search" 
      class="full-flex-row mt-4">
      <v-btn text value="*" :title="$t('shared.gender-all')" :small="$vuetify.breakpoint.xs">
        {{$t('shared.gender-all')}}
      </v-btn>
      <v-btn value="true" :small="$vuetify.breakpoint.xs">
        {{$t('events.race.filter-official-only')}}
      </v-btn>
      <v-btn value="false" :small="$vuetify.breakpoint.xs">
        {{$t('events.race.filter-virtual-only')}}
      </v-btn>
    </v-btn-toggle>
  </v-card-title>
</template>

<script>
import resultColumns from '@/util/resultColumns.js'

export default {
  name: "RaceResultFilter",
  components: {
  },
  props: {
      title: String,
      meta: Object,
      event: Object,
      race: Object,
      filter: Object,
      adminMode: Boolean,
      showRace: {
        type: Boolean,
        default: true,
      },
      showRaceDropdown: {
        type: Boolean,
        default: true,
      },
  },
  data() {
    return {
      gender: '*',
      onlyOfficialResults: '*',
      category: null,
      resultSearch: null,
      raceId: null,
      columns: null,
      races: [],
    };
  },
  async mounted() {
    this.races = this.event.races;
    this.raceId = this.race.id;
    if (this.filter) {
      this.loadFilter(this.filter);
    }
    var officialFromCookie = this.$cookies.get(this.cookieKey);
    if (officialFromCookie) {
      this.onlyOfficialResults = this.$cookies.get(this.cookieKey);
      this.search();
    }
    this.loadCustomColumns();
  },
  methods: {
    async loadFilter(filter) {
      this.resultSearch = filter.search;
      this.category = filter.category;
      this.gender = filter.gender;
      this.onlyOfficialResults = filter.onlyOfficialResults;
    },

    async search() {
      console.log('Search filter updating...');
      this.$cookies.set(this.cookieKey, this.onlyOfficialResults);
      this.$emit('change', { 
        search: this.resultSearch, 
        onlyOfficialResults: this.onlyOfficialResults, 
        category: this.category == 'All' ? null : this.category, 
        gender: this.gender == '*' ? null : this.gender 
      });
    },

    hasColumn(type) {
      if (this.columns) {
        var col = this.columns.find(x => x.type === type);
        //console.log('hasColumn config', type, col && col.visible, col, this.columns);
        return col && col.visible;
      }
      //console.log('hasColumn WITHOUT config', type);
      return false;
    },

    loadCustomColumns() {
      if (this.race) {
        this.columns = resultColumns.mergeAndFilterResultColumns(this.event, this.race, this.meta, this.race.cols);
        //console.log('Column config:', this.columns, this.meta, 'config', this.race.cols);
      }
    },
    
  },
  computed: {
    categoryList() {
      return this.meta.categories ? ['All'].concat(this.meta.categories) : [];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed';
    },
    showGenderFilter() {
      return this.race && !(this.race.top_x || this.race.top_x === 0) && this.columns && this.hasColumn('GENDER');
    },
    showCategoryFilter() {
      return this.race && !(this.race.top_x || this.race.top_x === 0) &&  this.columns && this.hasColumn('CATEGORY');
    },
    cookieKey() {
      return this.event && this.race ? `leaderboard.filter.official.${this.event.id}_${this.race.id}` : 'leaderboard.filter.official';
    },
  },
  watch: {
    race() {
      this.raceId = this.race.id;
      this.loadCustomColumns();
    },
    meta() {
      this.loadCustomColumns();
    },
  }

};
</script>
<style lang="scss">
.result-filter--gender {  }
.result-filter--category { max-width: 180px; float: right; }

.result-filter--search { max-width: 350px; float: right; }
.result-filter--search.small { max-width: 100%; margin-top: 10px; }
.v-text-field--rounded.small .v-input__prepend-outer { margin-top: 4px !important; }
.v-text-field--rounded.small .v-input__control { min-height: 36px !important; }
.v-text-field--rounded.small input { padding: 0 !important; }

.full-flex-row { flex-basis: 100% !important;}

</style>


import services from "./index";
import qs from "querystring";

export default {
    me(ip) {
        return services.http.get(`/v1.0/tiles/me?ip=${ip}`);
    },
    getCountries() {
        return services.http.get(`/v1.0/tiles/countries`);
    },
    getCountry(countrySlug) {
        return services.http.get(`/v1.0/tiles/country/${countrySlug}`);
    },
    get(id) {
        return services.http.get(`/v1.0/tiles/${id}`);
    },
    getAround(id) {
        return services.http.get(`/v1.0/tiles/${id}/around`);
    },
    getList(ids) {
        return services.http.post("/v1.0/tiles/list", ids);
    },
    getRanking(id, activityType, rankingKey) {
        return services.http.get(`/v1.0/tiles/${id}/ranking?activity=${(activityType||'running').toLowerCase()}&key=${rankingKey||''}`);
    },
    getEventRanking(id, activityType, rankingKey) {
        return services.http.get(`/v1.0/tiles/${id}/ranking/events?activity=${(activityType||'running').toLowerCase()}&key=${rankingKey||''}`);
    },
    getRankingPaged(id, activityType, grouping, rankingKey, start, limit, continuationToken) {
        const query = {
            activity: (activityType||'running').toLowerCase(),
            key: rankingKey,
            grouping: (grouping||'').toLowerCase(),
            start: start || 0,
            limit: limit || 10,
            continuationToken: continuationToken,
        };
        return services.http.post(`/v1.0/tiles/${id}/ranking`, qs.stringify(query));
    },
    search(tileset, query) {
        return services.http.post(`/v1.0/tiles/${tileset}/search`,qs.stringify(query));
    },
}